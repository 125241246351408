import Script from 'next/script';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIsAuthenticated } from '@/app/hooks/useIsAuthenticated';
import { initFive9Proactive, initFive9Widget, removeFive9Widget } from '@/app/utils/five9-chat';
import { useContext } from 'react';
import { getRetailerConfig } from '@/app/services/retailerService';
import { AppContext } from '@/app/appProvider';
import { useTranslations } from 'next-intl';
import { getEnvironment, EnvironmentEnum } from '@/app/utils/constants';

export default function Five9ChatSnippets() {
  const { isAuthenticated } = useIsAuthenticated();
  const [widgetReady, setWidgetReady] = useState(false);

  const appContext = useContext(AppContext);
  const retailerConfig = getRetailerConfig(appContext.srcPartnerId);
  const t = useTranslations('components.chatWidget');

  useLayoutEffect(() => {
    /** This handles the case, when user logs out and the script is already loaded.  */
    if (!widgetReady && isAuthenticated) {
      const widgetScript = document.getElementById('five9Widget');
      if (widgetScript) setWidgetReady(true);
    }
  }, [widgetReady, isAuthenticated]);

  useEffect(() => {
    if (widgetReady && isAuthenticated) {
      const environment = getEnvironment(process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT);
      let title = retailerConfig?.name;
      if (environment !== EnvironmentEnum.PRODUCTION) {
        title = 'Test'; // Chat is already added in the options in the initFive9Widget function
      }
      initFive9Widget(title, t('title'));
    }

    const five9Icons = document.getElementsByClassName('five9-icon');
    if (five9Icons && five9Icons.length > 0) {
      for (let i = 0; i < five9Icons.length; i++) {
        const five9Icon = five9Icons[i];
        if (five9Icon.hasChildNodes()) continue;
        five9Icon.innerHTML = `
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8085_1381)">
        <path d="M6 8.75C6 8.02065 6.28973 7.32118 6.80546 6.80546C7.32118 6.28973 8.02065 6 8.75 6H25.25C25.9793 6 26.6788 6.28973 27.1945 6.80546C27.7103 7.32118 28 8.02065 28 8.75V19.75C28 20.4793 27.7103 21.1788 27.1945 21.6945C26.6788 22.2103 25.9793 22.5 25.25 22.5H12.0693C11.7046 22.5001 11.3549 22.645 11.0971 22.9029L7.17425 26.8258C7.07818 26.9221 6.9557 26.9877 6.8223 27.0144C6.6889 27.0411 6.55059 27.0276 6.42487 26.9756C6.29915 26.9236 6.19168 26.8355 6.11605 26.7225C6.04041 26.6094 6.00003 26.4764 6 26.3404V8.75ZM10.8125 10.125C10.6302 10.125 10.4553 10.1974 10.3264 10.3264C10.1974 10.4553 10.125 10.6302 10.125 10.8125C10.125 10.9948 10.1974 11.1697 10.3264 11.2986C10.4553 11.4276 10.6302 11.5 10.8125 11.5H23.1875C23.3698 11.5 23.5447 11.4276 23.6736 11.2986C23.8026 11.1697 23.875 10.9948 23.875 10.8125C23.875 10.6302 23.8026 10.4553 23.6736 10.3264C23.5447 10.1974 23.3698 10.125 23.1875 10.125H10.8125ZM10.8125 13.5625C10.6302 13.5625 10.4553 13.6349 10.3264 13.7639C10.1974 13.8928 10.125 14.0677 10.125 14.25C10.125 14.4323 10.1974 14.6072 10.3264 14.7361C10.4553 14.8651 10.6302 14.9375 10.8125 14.9375H23.1875C23.3698 14.9375 23.5447 14.8651 23.6736 14.7361C23.8026 14.6072 23.875 14.4323 23.875 14.25C23.875 14.0677 23.8026 13.8928 23.6736 13.7639C23.5447 13.6349 23.3698 13.5625 23.1875 13.5625H10.8125ZM10.8125 17C10.6302 17 10.4553 17.0724 10.3264 17.2014C10.1974 17.3303 10.125 17.5052 10.125 17.6875C10.125 17.8698 10.1974 18.0447 10.3264 18.1736C10.4553 18.3026 10.6302 18.375 10.8125 18.375H17.6875C17.8698 18.375 18.0447 18.3026 18.1736 18.1736C18.3026 18.0447 18.375 17.8698 18.375 17.6875C18.375 17.5052 18.3026 17.3303 18.1736 17.2014C18.0447 17.0724 17.8698 17 17.6875 17H10.8125Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_8085_1381">
          <rect width="22" height="22" fill="white" transform="translate(6 6)"/>
          </clipPath>
        </defs>
        </svg>
        `;
      }
    }
    return () => {
      if (widgetReady) {
        removeFive9Widget();
      }
    };
  }, [isAuthenticated, widgetReady]);

  return (
    <>
      <Script
        id="embedScript"
        strategy="lazyOnload"
        src="https://embed.rcrsv.io/DTODRWt2X3wgFMypUcAyEkssX4EICYXFzzMFRIlIxeabKou86NxwKzblznebOYCK"
      />
      <Script
        id="five9Widget"
        strategy="lazyOnload"
        onLoad={() => {
          setWidgetReady(true);
        }}
        src="https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js"
      />
      <Script
        id="five9Proactive"
        strategy="lazyOnload"
        onLoad={() => {
          initFive9Proactive(t('title'));
        }}
        src="https://app.five9.com/consoles/ProactiveChat/javascripts/five9proactivechat.js"
      />
    </>
  );
}
