import React, { useContext } from 'react';
import { useIsAuthenticated } from '@/app/hooks/useIsAuthenticated';
import AblyChatSnippet from './ablychat/AblyChatSnippet';
import Five9ChatSnippets from './five9chat/Five9ChatSnippets';
import { AppContext } from '@/app/appProvider';
import { getRetailerConfig, getRetailerMappingByHostname } from '@/app/services/retailerService';

export default function LiveChat() {
  const { isAuthenticated } = useIsAuthenticated();
  const { features } = useContext(AppContext);
  const srcPartnerId = getRetailerMappingByHostname(window.location.hostname).srcPartnerId;
  const retailerConfig = getRetailerConfig(srcPartnerId ?? 0);

  if (!isAuthenticated) return null;

  if (retailerConfig?.disableChat) return null;

  if (features.ablyChat) {
    return <AblyChatSnippet />;
  }

  return <Five9ChatSnippets />;
}
