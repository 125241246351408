'use client';

import React, { memo, useCallback, useMemo, useContext } from 'react';
import { AppContext } from '@/app/appProvider';
import OffCanvas from '@/app/components/shared/OffCanvas/OffCanvas';
import SidebarItem, { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import { useTranslations } from 'next-intl';
import Divider from '@/app/components/shared/Divider/Divider';
import routes from '@/app/utils/routes';
import {
  ByAccountOutlined,
  ByHomeOutlined,
  CByNavPaymentHistory,
} from '@/app/components/icons/CustomIconPack';
import { BsBoxArrowRight } from 'react-icons/bs';
import { signOut } from 'next-auth/react';
import { useScreenWidth } from '@/app/hooks/useScreenWidth';
import { useIsAuthenticated } from '@/app/hooks/useIsAuthenticated';
import { CustomerProjectsResponse } from '@/app/types/models';
import { useParams } from 'next/navigation';
import useTracking from '@/app/hooks/useTracking';
import { getCategoryTranslation } from '@/app/utils/translations';
import {
  ButtonEventProperties,
  NavEventProperties,
  ButtonEvents,
} from '@/app/utils/trackingEvents';
import { formatPhoneNumberWithParens } from '@/app/utils/helpers';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
  customerProjects: CustomerProjectsResponse | undefined;
};

const Sidebar = ({ customerProjects, isOpen, toggle, close }: Props) => {
  const t = useTranslations('components.navMenu.sidebar');
  const { isLargeDesktop } = useScreenWidth();
  const { isAuthenticated } = useIsAuthenticated();
  const { workOrderId } = useParams();
  const { events, trackClick } = useTracking();
  const { retailerConfig, displayPhoneNumber } = useContext(AppContext);

  const onClickCloseNavMobile = useCallback(() => {
    if (!isLargeDesktop) {
      close();
    }
  }, [isLargeDesktop, close]);

  const categories = useMemo(
    () =>
      customerProjects?.categories?.map((category) => {
        const firstWorkOrderId = category?.workOrderIds?.[0];
        const target = `${routes.workOrder}/${firstWorkOrderId}`;
        return {
          name: getCategoryTranslation(t, category?.categoryName),
          href: target,
          onClick: () => {
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: target,
              category: category?.categoryName,
            };
            trackClick(events.NAV_CLICKED, trackingProps);
            onClickCloseNavMobile();
          },
          isActive: category.workOrderIds.includes(+workOrderId),
        };
      }) || [],
    [customerProjects?.categories, onClickCloseNavMobile, t, workOrderId],
  );

  const sidebarItems: SidebarItemProps[] = useMemo(
    () => [
      {
        id: '1',
        name: t('buttons.myProjects'),
        isCollapsible: true,
        icon: <ByHomeOutlined size={24} />,
        subItems: categories,
        open: true,
        onClick: () => {
          const trackingProps: ButtonEventProperties = {
            component: 'Sidebar',
            buttonEvent: ButtonEvents.MyProjectsExpanded,
          };
          trackClick(events.BUTTON_CLICKED, trackingProps);
        },
      },
      {
        id: '4',
        name: t('buttons.paymentHistory'),
        isCollapsible: false,
        icon: <CByNavPaymentHistory size={24} />,
        href: routes.paymentHistory,
        onClick: () => {
          const trackingProps: NavEventProperties = {
            component: 'Sidebar',
            target: routes.paymentHistory,
          };
          trackClick(events.NAV_CLICKED, trackingProps);
          onClickCloseNavMobile();
        },
      },
      {
        id: '5',
        name: t('buttons.manageProfile'),
        isCollapsible: false,
        icon: <ByAccountOutlined size={24} />,
        href: routes.profile,
        onClick: () => {
          const trackingProps: NavEventProperties = {
            component: 'Sidebar',
            target: routes.profile,
          };
          trackClick(events.NAV_CLICKED, trackingProps);
          onClickCloseNavMobile();
        },
      },
    ],
    [t, categories, onClickCloseNavMobile],
  );

  const renderSidebarItemSignOut = () => (
    <SidebarItem
      id={'signOut'}
      name={t('buttons.signOut')}
      isCollapsible={false}
      icon={
        <span className={'sidebar-item-sign-out-wrapper'}>
          <BsBoxArrowRight size={18} />
        </span>
      }
      onClick={async () => {
        await signOut({
          redirect: false,
          callbackUrl: routes.login,
        });
        trackClick(events.BUTTON_CLICKED, {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.SignOut,
        } as ButtonEventProperties);
      }}
    />
  );

  const renderRetailerSidebarItems = () => {
    const hasRetailerSidebarItems = !!retailerConfig?.sidebarItems?.();
    if (hasRetailerSidebarItems) {
      return (
        <>
          {retailerConfig?.sidebarItems?.()?.map((item) => (
            <SidebarItem key={item.id} {...item} />
          ))}
          <Divider />
        </>
      );
    }
  };
  return (
    <OffCanvas
      isOpen={isAuthenticated && isOpen}
      toggle={toggle}
      title={t('heading')}
      className={'offcanvas-header-sidebar'}
      fade={!isLargeDesktop}
      backdrop={!isLargeDesktop}
    >
      {sidebarItems.map((item) => (
        <SidebarItem key={item.id} {...item} />
      ))}
      <Divider />

      {renderRetailerSidebarItems()}
      {renderSidebarItemSignOut()}
      <Divider />

      <div className={'sidebar-contact-wrapper'}>
        <p className={'sidebar-contact-heading'}>{t('contact.heading')}</p>
        <p className={'sidebar-contact-description'}>
          {t('contact.description', {
            phone: formatPhoneNumberWithParens(displayPhoneNumber),
          })}
        </p>
      </div>
    </OffCanvas>
  );
};

export default memo(Sidebar);
